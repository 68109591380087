import React from "react";
import PropTypes from "prop-types";

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
  names: [],
  submitted: false,
  success: false,
  language: "en",
  dateApplied: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_NAMES": {
      return {
        ...state,
        names: action.payload,
      };
    }
    case "SET_SUCCESS": {
      return {
        ...state,
        success: action.payload,
      };
    }
    case "SET_SUBMITTED": {
      return {
        ...state,
        submitted: action.payload,
      };
    }
    case "SET_LANGUAGE": {
      return {
        ...state,
        language: action.payload,
      };
    }
    case "SET_DATE_APPLIED": {
      return {
        ...state,
        dateApplied: action.payload,
      };
    }
    default:
      throw new Error("Bad Action Type");
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalContextProvider;
